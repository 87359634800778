import { getAuthToken } from "@/lib/use-auth";
import { Datapoint, Dataset, DatasetResponse } from "@/types/app/dataset";
import { Page } from "@/types/generic";
import useSWR, { Arguments, SWRConfiguration, mutate } from "swr";
import { ApiService } from "./api.service";
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { User } from "@/types/app/user";
import dayjs from "dayjs";
import { parseTimestamp, parseTimestampsInResponse } from "./utils";
import { EvaluationStatus } from "./evaluations.service";
import { File, FileResponse, parseFileResponse } from "@/types/app/file";
import { Evaluator, EvaluatorResponse } from "@/types/app/evaluator";
import { Log, LogResponse, parseLogResponse } from "@/types/app/log";

interface EvaluationEvaluator {
  version: Evaluator;
  orchestrated: boolean;
  added_at: dayjs.Dayjs;
}
type EvaluationEvaluatorResponse = Omit<EvaluationEvaluator, "version" | "added_at"> & {
  version: EvaluatorResponse;
  added_at: string;
};

export interface Evaluation {
  id: string;
  name: string | null;
  runs_count: number;
  evaluators: EvaluationEvaluator[];
  created_by: User | null;
  created_at: dayjs.Dayjs;
  updated_at: dayjs.Dayjs;
}

export const useEvaluation = (evaluationId: string | undefined, swrOptions: SWRConfiguration<Evaluation> = {}) => {
  const { data, error, mutate } = useSWR<Evaluation>(
    evaluationId && [`/v5/evaluations/${evaluationId}`, getAuthToken()],
    evaluationFetcher,
    swrOptions,
  );
  return { evaluation: data, error, loading: !data && !error, mutate };
};

const evaluationFetcher = async (...args: Parameters<typeof ApiService.getWithToken>): Promise<Evaluation> => {
  const response: EvaluationResponse = await ApiService.getWithToken(...args);
  return parseEvaluationResponse(response);
};

interface PagedEvaluationQueryParams {
  fileId: string;
  page?: number;
  size?: number;
  status?: EvaluationStatus[];
  datasetVersionIds?: string[];
  evaluatedVersionIds?: string[];
  evaluatorVersionIds?: string[];
}

const getPagedEvaluationsUrl = (params: PagedEvaluationQueryParams): string => {
  const urlParams = new URLSearchParams();
  urlParams.append("file_id", params.fileId);
  urlParams.append("page", (params.page ?? 1).toString());
  urlParams.append("size", (params.size ?? 10).toString());
  if (params.status) {
    params.status.forEach((status) => urlParams.append("status", status));
  }
  if (params.datasetVersionIds) {
    params.datasetVersionIds.forEach((id) => urlParams.append("dataset_version_id", id));
  }
  if (params.evaluatedVersionIds) {
    params.evaluatedVersionIds.forEach((id) => urlParams.append("version_id", id));
  }
  if (params.evaluatorVersionIds) {
    params.evaluatorVersionIds.forEach((id) => urlParams.append("evaluator_version_id", id));
  }

  return `/v5/evaluations?${urlParams.toString()}`;
};

export const getPagedEvaluations = async (params: PagedEvaluationQueryParams): Promise<Page<Evaluation>> => {
  const response: AxiosResponse<Page<EvaluationResponse>> = await ApiService.get(getPagedEvaluationsUrl(params));

  return {
    ...response.data,
    records: response.data.records.map(parseEvaluationResponse),
  };
};

export const usePagedEvaluations = (
  params: Partial<PagedEvaluationQueryParams>, // Allow for undefined fileId. If fileId is undefined, the SWR hook will not fetch data.
  swrOptions: SWRConfiguration<Page<Evaluation>> = {},
) => {
  const { data, error, mutate } = useSWR<Page<Evaluation>>(
    params.fileId ? [getPagedEvaluationsUrl({ ...params, fileId: params.fileId }), getAuthToken()] : null,
    pagedEvaluationsFetcher,
    swrOptions,
  );
  return { data, error, loading: !data && !error, mutate };
};

interface EvaluationResponse extends Omit<Evaluation, "evaluators" | "created_at" | "updated_at"> {
  evaluators: EvaluationEvaluatorResponse[];
  created_at: string;
  updated_at: string;
}

export const parseEvaluationResponse = (response: EvaluationResponse): Evaluation => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at"]),
    evaluators: response.evaluators.map((evaluator) => ({
      ...evaluator,
      version: parseFileResponse(evaluator.version),
      added_at: parseTimestamp(evaluator.added_at),
    })),
  };
};

const pagedEvaluationsFetcher = async (
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<Page<Evaluation>> => {
  const response: Page<EvaluationResponse> = await ApiService.getWithToken(...args);
  return {
    ...response,
    records: response.records.map(parseEvaluationResponse),
  };
};

export interface EvaluationRun {
  id: string;
  dataset: Dataset | null;
  version: File | null;
  batch_id: string | null;
  orchestrated: boolean;
  control: boolean;
  added_at: dayjs.Dayjs;
  created_at: dayjs.Dayjs;
  created_by: User | null;
  status: EvaluationStatus;
}

type EvaluationRunResponse = Omit<EvaluationRun, "dataset" | "version" | "added_at" | "created_at"> & {
  dataset: DatasetResponse | null;
  version: FileResponse | null;
  added_at: string;
  created_at: string;
};

const parseEvaluationRunResponse = (response: EvaluationRunResponse): EvaluationRun => {
  return {
    ...response,
    dataset: response.dataset ? parseFileResponse(response.dataset) : null,
    version: response.version ? parseFileResponse(response.version) : null,
    added_at: parseTimestamp(response.added_at),
    created_at: parseTimestamp(response.created_at),
  };
};

const evaluationRunsFetcher = async (
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<{ runs: EvaluationRun[] }> => {
  const response: { runs: EvaluationRunResponse[] } = await ApiService.getWithToken(...args);
  return {
    runs: response.runs.map(parseEvaluationRunResponse),
  };
};

export const useEvaluationRuns = (
  evaluationId: string | undefined | null,
  swrOptions: SWRConfiguration<{ runs: EvaluationRun[] }> = {},
) => {
  const { data, error, mutate } = useSWR<{ runs: EvaluationRun[] }>(
    evaluationId ? [`/v5/evaluations/${evaluationId}/runs`, getAuthToken()] : null,
    evaluationRunsFetcher,
    swrOptions,
  );
  return { runs: data?.runs, error, loading: !data && !error, mutate };
};

export interface CreateRunRequest {
  dataset?: DatasetRequest | null;
  version?: { version_id: string } | null;
  orchestrated: boolean;
  use_existing_logs?: boolean;
}

export const createEvaluationRun = async (evaluationId: string, request: CreateRunRequest): Promise<EvaluationRun> => {
  const response = (await ApiService.post(`/v5/evaluations/${evaluationId}/runs`, request)).data;
  return parseEvaluationRunResponse(response);
};

export const addExistingRunToEvaluation = async ({
  evaluationId,
  runId,
}: {
  evaluationId: string;
  runId: string;
}): Promise<EvaluationRun> => {
  const response = (await ApiService.post(`/v5/evaluations/${evaluationId}/runs/${runId}`)).data;
  return parseEvaluationRunResponse(response);
};

export const deleteEvaluationRun = (evaluationId: string, runId: string): AxiosPromise<void> => {
  return ApiService.remove(`/v5/evaluations/${evaluationId}/runs/${runId}`);
};

export type EvaluationStatsResponse = {
  overall_stats: {
    num_datapoints: number;
    total_logs: number;
    total_evaluator_logs: number;
    total_human_evaluator_logs: number;
    total_completed_human_evaluator_logs: number;
  };
  run_stats: {
    run_id: string;
    version_id: string | null;
    batch_id: string | null;
    num_logs: number;
    evaluator_stats: EvaluationEvaluatorStats[];
    status: EvaluationStatus;
  }[];
};

export type BaseEvaluationEvaluatorStats = {
  evaluator_version_id: string;
  total_logs: number;
  num_judgments: number;
  num_nulls: number;
  num_errors: number;
};

export interface EvaluationNumericStats extends BaseEvaluationEvaluatorStats {
  mean: number;
  std: number;
  percentiles: Record<string, number>;
}

export interface EvaluationBooleanStats extends BaseEvaluationEvaluatorStats {
  num_true: number;
  num_false: number;
}

export interface EvaluationSelectStats extends BaseEvaluationEvaluatorStats {
  num_judgments_per_option: Record<string, number>;
}

export interface EvaluationTextStats extends BaseEvaluationEvaluatorStats {}

export type EvaluationEvaluatorStats =
  | EvaluationNumericStats
  | EvaluationBooleanStats
  | EvaluationSelectStats
  | EvaluationTextStats;

export const isNumericStats = (stats: EvaluationEvaluatorStats): stats is EvaluationNumericStats => {
  return "mean" in stats;
};

export const isBooleanStats = (stats: EvaluationEvaluatorStats): stats is EvaluationBooleanStats => {
  return "num_true" in stats;
};

export const isSelectStats = (stats: EvaluationEvaluatorStats): stats is EvaluationSelectStats => {
  return "num_judgments_per_option" in stats;
};

export const isTextStats = (stats: EvaluationEvaluatorStats): stats is EvaluationTextStats => {
  return !isNumericStats(stats) && !isBooleanStats(stats) && !isSelectStats(stats);
};

export const useEvaluationStats = (
  evaluationId: string | undefined,
  swrOptions: SWRConfiguration<EvaluationStatsResponse> = {},
) => {
  const { data, error, mutate } = useSWR<EvaluationStatsResponse>(
    evaluationId && [`/v5/evaluations/${evaluationId}/stats`, getAuthToken()],
    swrOptions,
  );
  return { stats: data, error, loading: !data && !error, mutate };
};

export const mutateEvaluationAndStats = (evaluationId: string) => {
  return mutate(
    (key: Arguments) =>
      Array.isArray(key) &&
      (key[0] === `/v5/evaluations/${evaluationId}/stats` ||
        key[0] === `/v5/evaluations/${evaluationId}` ||
        key[0] === `/v5/evaluations`),
  );
};

export interface CategoricalField {
  type: "categorical";
  values: any[];
}

export interface TimestampField {
  type: "timestamp";
}

export type FieldOptions = {
  name: string;
  sortable: boolean;
  filterable: CategoricalField | TimestampField | null;
};

export const useEvaluationLogsFieldOptions = (
  evaluationId?: string,
  swrOptions: SWRConfiguration<FieldOptions[]> = {},
) => {
  const { data, error, mutate } = useSWR<FieldOptions[]>(
    [`/v5/evaluations/${evaluationId}/logs/fields`, getAuthToken()],
    swrOptions,
  );
  return { fieldOptions: data, error: error, loading: !data && !error, mutate };
};

interface EvaluationFileRequest {
  id: string;
  // This technically either accepts `id` or `path`, but we only use `id` in the frontend.
  // path?: string;
}
interface DatasetRequest {
  version_id: string;
}

interface EvaluatorRequest {
  version_id: string;
  orchestrated: boolean;
}

export interface CreateEvaluationRequest {
  file: EvaluationFileRequest;
  evaluators: EvaluatorRequest[];
  name?: string;
}

export const createEvaluation = async (request: CreateEvaluationRequest): Promise<Evaluation> => {
  const response = (await ApiService.post(`/v5/evaluations`, request)).data;
  return parseEvaluationResponse(response);
};

export const duplicateEvaluation = async (evaluationId: string): Promise<Evaluation> => {
  const response = (await ApiService.post(`/v5/evaluations/${evaluationId}/duplicate`)).data;
  return parseEvaluationResponse(response);
};

export const deleteEvaluation = (evaluationId: string): AxiosPromise<void> => {
  return ApiService.remove(`/v5/evaluations/${evaluationId}`);
};

export interface UpdateEvaluationRequest {
  name?: string;
}

export const updateEvaluation = async (evaluationId: string, request: UpdateEvaluationRequest): Promise<Evaluation> => {
  const response = (await ApiService.patch(`/v5/evaluations/${evaluationId}`, request)).data;
  return parseEvaluationResponse(response);
};

export const addEvaluators = async (evaluationId: string, evaluators: EvaluatorRequest[]): Promise<Evaluation> => {
  const response = (await ApiService.post(`/v5/evaluations/${evaluationId}/evaluators`, { evaluators })).data;
  return parseEvaluationResponse(response);
};

export const removeEvaluator = async (evaluationId: string, evaluatorVersionId: string): Promise<Evaluation> => {
  const response = (await ApiService.remove(`/v5/evaluations/${evaluationId}/evaluators/${evaluatorVersionId}`)).data;
  return parseEvaluationResponse(response);
};

interface RunEvaluatorLogCounts {
  evaluator_version_id: string;
  num_logs: number;
}

export interface RunLogCounts {
  num_logs: number;
  num_evaluator_logs: RunEvaluatorLogCounts[];
}

export const getEvaluationLogCounts = async (
  request: {
    runs: (string | CreateRunRequest)[];
    evaluators: string[];
  },
  config?: AxiosRequestConfig,
): Promise<RunLogCounts[]> => {
  const response: AxiosResponse<{ log_counts: RunLogCounts[] }> = await ApiService.post(
    `/v5/evaluations/log-counts`,
    request,
    config,
  );
  return response.data.log_counts;
};

export interface EvaluationLog {
  run_id: string;
  datapoint: Datapoint | null;
  log: Log;
  evaluator_logs: Log[];
}

export interface EvaluationLogResponse extends Omit<EvaluationLog, "log" | "evaluator_logs"> {
  log: LogResponse;
  evaluator_logs: LogResponse[];
}

export const parseEvaluationLog = (response: EvaluationLogResponse): EvaluationLog & { id: string } => {
  const evaluationLog = {
    ...response,
    log: parseLogResponse(response.log),
    evaluator_logs: response.evaluator_logs.map(parseLogResponse),
  };
  return {
    ...evaluationLog,
    id: evaluationLog.log.id,
  };
};

export const useEvaluationLogs = (
  props?: { evaluationId: string; page: number; size: number; runIds?: string[] | null },
  swrOptions: SWRConfiguration<Page<EvaluationLogResponse>> = {},
) => {
  let url: string | null = null;
  if (props) {
    const { evaluationId, page, size } = props;
    const params = new URLSearchParams();
    params.append("page", page.toString());
    params.append("size", size.toString());
    if (props.runIds) {
      props.runIds.forEach((runId) => params.append("run_id", runId));
    }
    url = `/v5/evaluations/${evaluationId}/logs?${params.toString()}`;
  }

  const { data, error, mutate } = useSWR<Page<EvaluationLogResponse>>(
    props && url ? [url, getAuthToken()] : null,
    swrOptions,
  );
  return { pagedResults: data, error: error, loading: !data && !error, mutate };
};

/** Helper method for the detailed column view */
export const useEvaluationDatapoint = (
  evaluation: Evaluation,
  indexOrId: number | string,
  page: number,
  size: number,
  filterQuery: string,
) => {
  const { pagedDatapoints, error, loading, mutate } = useEvaluationDatapoints({
    evaluation,
    page,
    size,
    filters: filterQuery,
  });

  // Pre-fetch the next page.
  useEvaluationDatapoints({ evaluation, page: page + 1, size: size, filters: filterQuery });

  if (typeof indexOrId === "string") {
    // indexOrId is datapoint id.
    const datapoint = pagedDatapoints?.records.find((datapoint) => datapoint.datapoint?.id === indexOrId);
    const index = datapoint ? pagedDatapoints?.records.indexOf(datapoint) : -1;
    return {
      datapoint,
      error,
      loading,
      index,
      mutate,
      total: pagedDatapoints?.total,
      pagedDatapoints: pagedDatapoints,
    };
  }

  const datapointIndex = indexOrId % size;
  const datapoint = pagedDatapoints?.records[datapointIndex];
  return {
    datapoint,
    error,
    index: datapointIndex,
    loading,
    mutate,
    total: pagedDatapoints?.total,
    pagedDatapoints: pagedDatapoints,
  };
};

export interface EvaluationDatapointLog {
  run_id: string;
  log: Log;
}

export interface EvaluationDatapoint {
  datapoint: Datapoint | null;
  logs: EvaluationDatapointLog[];
}
interface EvaluationDatapointResponse extends Omit<EvaluationDatapoint, "logs"> {
  logs: (Omit<EvaluationDatapointLog, "log"> & { log: LogResponse })[];
}

export const useEvaluationDatapoints = (
  {
    evaluation,
    page,
    size,
    filters,
  }: {
    evaluation: Evaluation;
    page: number;
    size: number;
    filters: string;
  },
  swrOptions: SWRConfiguration<Page<EvaluationDatapoint>> = {},
) => {
  let filterQueryParam = "";
  if (filters) {
    filterQueryParam = `${filters}`;
  }
  const { data, error, mutate } = useSWR<Page<EvaluationDatapoint>>(
    [`/v5/evaluations/${evaluation.id}/datapoints?page=${page}&size=${size}${filterQueryParam}`, getAuthToken()],
    evaluationDatapointFetcher,
    { ...swrOptions, revalidateIfStale: filters ? true : false },
  );
  return { pagedDatapoints: data, error, loading: !data && !error, mutate };
};

const evaluationDatapointFetcher = async (
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<Page<EvaluationDatapoint>> => {
  const response: Page<EvaluationDatapointResponse> = await ApiService.getWithToken(...args);
  return {
    ...response,
    records: response.records.map((record) => ({
      ...record,
      logs: record.logs.map((log) => ({ ...log, log: parseLogResponse(log.log) })),
    })),
  };
};

// Helper function to construct a create request (for creating, updating, and fetching missing data)
// from what's available in the context.
export const getCreateEvaluationRequest = ({
  fileId,
  evaluationName,
  evaluatorVersions,
}: {
  fileId: string;
  evaluationName?: string;
  evaluatorVersions: Pick<Evaluator, "version_id">[];
}): CreateEvaluationRequest => {
  return {
    file: { id: fileId },
    name: evaluationName,
    evaluators: evaluatorVersions.map((v) => ({ version_id: v.version_id, orchestrated: true })),
  };
};

const IN_PROGRESS_EVALUATION_STATUSES: EvaluationStatus[] = ["pending", "running"];

// Polls Evaluation Report and Stats if the report's status is pending (i.e. not completed).
export const usePollEvaluationRuns = (evaluationId?: string, displayedRuns?: EvaluationRun[]) => {
  const shouldPoll = displayedRuns?.some((run) => IN_PROGRESS_EVALUATION_STATUSES.includes(run.status));
  useEvaluationRuns(evaluationId, {
    dedupingInterval: 4000,
    refreshInterval: (latestData) => {
      if (!latestData) {
        // Not loaded yet, don't refresh.
        return 0;
      }
      if (shouldPoll) {
        return 5000;
      }
      return 0;
    },
  });
  useEvaluationStats(evaluationId, {
    dedupingInterval: 4000,
    refreshInterval: shouldPoll ? 5000 : 0,
  });
};

interface UpdateEvaluationRun {
  control?: true;
  status?: EvaluationStatus;
}

export const updateEvaluationRun = async ({
  evaluationId,
  runId,
  update,
}: {
  evaluationId: string;
  runId: string;
  update: UpdateEvaluationRun;
}): Promise<EvaluationRun> => {
  const response = (await ApiService.patch(`/v5/evaluations/${evaluationId}/runs/${runId}`, update)).data;
  return parseEvaluationRunResponse(response);
};

export const setControl = async (evaluationId: string, runId: string): Promise<EvaluationRun> => {
  return updateEvaluationRun({ evaluationId, runId, update: { control: true } });
};

export const setStatus = async (
  evaluationId: string,
  runId: string,
  status: EvaluationStatus,
): Promise<EvaluationRun> => {
  return updateEvaluationRun({ evaluationId, runId, update: { status } });
};
